import request from '@/utils/request'

//商业话题--分页查询
export function getBusinessTopic(data) {
    return request({
        url: '/businessTopic/page',
        method: 'post',
        data
    })
}

//商业话题--新增话题
export function addBusinessTopic(data) {
    return request({
      url: '/businessTopic/add',
      method: 'post',
      data
    })
}

//商业话题--编辑话题
export function editBusinessTopic(data) {
    return request({
      url: '/businessTopic/edit',
      method: 'post',
      data
    })
}

//商业话题--批量发布
export function publishTopic(data) {
    return request({
      url: '/businessTopic/publish',
      method: 'POST',
      data
    })
}

//商业话题--详情查询
export function getDetail(data) {
    return request({
        url: '/businessTopic',
        method: 'get',
        params: data
    })
}

//商业话题--批量删除
export function deleteBusinessTopic(data) {
    return request({
        url: '/businessTopic/del',
        method: 'post',
        data
    })
}

//商业话题--查询话题下想联系用户
export function getContact(data) {
    return request({
        url: '/businessTopicContact',
        method: 'get',
        params: data
    })
}

//商业话题--查询话题下感兴趣用户
export function getInterested(data) {
    return request({
        url: '/businessTopicInterested',
        method: 'get',
        params: data
    })
}

//商业话题--分页查询评论    
export function getTopicComment(data) {
    return request({
        url: ' /businessTopicComment/all',
        method: 'post',
        data
    })
}



//商业话题--评论删除
export function deleteMessage(data) {
    return request({
        url: '/businessTopicComment/del',
        method: 'get',
        params:data
    })
}

//商业话题--评论分页查询
export function getAllMessage(data) {
    return request({
        url: '/businessTopicComment/all',
        method: 'POST',
        data
    })
}