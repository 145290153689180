<template>
  <div class="user">
    <div class="line_one">
      <el-form inline :model="queryInfo" label-width="80px" ref="form">
        <el-form-item label="话题类型:">
          <el-button :class="tabIndex1 === '-1' ? 'active' : ''" style="margin-left:10px" @click="searchClick('-1', 1)">
            全部
          </el-button>
          <el-button :class="tabIndex1 === '0' ? 'active' : ''" @click="searchClick('0', 1)">图文</el-button>
          <el-button :class="tabIndex1 === '2' ? 'active' : ''" @click="searchClick('2', 1)">组图</el-button>
          <el-button :class="tabIndex1 === '1' ? 'active' : ''" @click="searchClick('1', 1)">文字</el-button>
        </el-form-item>
        <el-form-item label="发布状态:">
          <el-button :class="tabIndex2 === '-1' ? 'active' : ''" style="margin-left:10px" @click="searchClick('-1', 2)">
            全部
          </el-button>
          <el-button :class="tabIndex2 === '1' ? 'active' : ''" @click="searchClick('1', 2)">已发布</el-button>
          <el-button :class="tabIndex2 === '0' ? 'active' : ''" @click="searchClick('0', 2)">未发布</el-button>
        </el-form-item>
        <el-form-item label="话题描述:">
          <el-input v-model="queryInfo.topicDescribe" placeholder="请输入关键词" clearable />
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search()">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="line_two">
      <div class="buttonArea">
        <div class="add" @click="goAdd()">
          <i class="el-icon-plus"></i>
          新增话题
        </div>
        <div class="addSome" @click="publishTopic(1)">
          <i class="iconfont icon-jr-icon-meet"></i>
          批量发布
        </div>
        <div class="addSome" @click="publishTopic(0)">
          <i class="el-icon-delete"></i>
          批量删除
        </div>
      </div>
      <el-table :data="dataUserList" style="width: 100%" class="data_list" v-loading="loading"
        :header-cell-style="getRowClass" @select="select" @select-all="select">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" label="序号" sortable width="80" align="center" />
        <el-table-column label="话题图片" width="150" align="center">
          <template v-slot="{ row }">
            <!-- <el-popover placement="bottom-start" trigger="hover"> -->
              <div v-if="row.imgList">
                <img :src="row.imgList[0]" style="width:80px;height:60px" />
              </div>
              <!-- <img slot="reference" :src="row.imgList[0]" style="max-width:60px;max-height: 200px" /> -->
            <!-- </el-popover> -->
          </template>
        </el-table-column>
        <el-table-column label="话题描述" align="center" width="400px">
          <template v-slot='{ row }'>
            <!-- <el-popover
              placement="right"
              width="500"
              trigger="hover">
             <div class="bigOne">
                <div v-html="row.topicDescribe"></div>
             </div>
              <div v-html="row.topicDescribe" class="htmlTip" slot="reference"></div>
            </el-popover> -->
            <!-- <div v-html="row.topicDescribe" class="htmlTip"></div> -->
            <textFlow :info="row.topicDescribe" :lineClamp='2' :hiddenBtn="true"></textFlow>
          </template>
        </el-table-column>
        <el-table-column label="话题类型" prop="mobile" align="center" width="80">
          <template v-slot="{ row }">
            <div v-if="row.topicType == 0">图文话题</div>
            <div v-if="row.topicType == 1">文字话题</div>
            <div v-if="row.topicType == 2">组图话题</div>
          </template>
        </el-table-column>
        <el-table-column label="发布人" prop="userName" align="center" width="100" />
        <el-table-column label="发布时间" prop="updateTime" align="center" sortable width="180" />
        <!-- <el-table-column label="留言" align="center" sortable width="120" prop="messageCount">
          <template v-slot="{ row }">
            <div @click="goMessage('留言',row.id)" class="colorOne">
              {{row.messageCount > 999 ? '999+' : row.messageCount}}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="感兴趣" align="center" sortable width="120" prop="messageCount">
          <template v-slot="{ row }">
            <div @click="goMessage('感兴趣', row.id)" class="colorOne">
              {{ row.interestedCount > 999 ? '999+' : row.interestedCount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="想联系" align="center" sortable width="120" prop="relationCount">
          <template v-slot="{ row }">
            <div @click="goMessage('想联系', row.id)" class="colorOne">
              {{ row.relationCount > 999 ? '999+' : row.relationCount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <div class="maker">
              <div class="noPass" v-if="row.isSued == 1" @click="publishTopicTwo(row.id)">取消发布</div>
              <div class="put" v-if="row.isSued == 0" @click="publishTopicOne(row.id)">发布</div>
              <div class="edit" @click="goEdit(row.id)">编辑</div>
              <div class="delete" @click="deleteOne(row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination :current-page="queryInfo.current" :page-sizes="[10, 30, 50]" :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
  </div>
</template>


<script>
import textFlow from '@/components/textflow.vue'
import { pagination } from '@/mixins/pagination'
import { getBusinessTopic, deleteBusinessTopic, publishTopic } from "@/api/businessTopics.js"
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
  topicType: '-1',
  isSued: '-1'
})
export default {
  components: { textFlow },
  mixins: [pagination],
  data() {
    return {
      dataUserList: [{}],
      tabIndex1: '-1',
      tabIndex2: '-1',
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList: {},
      queryInfo: { ...defaultQueryInfo }
    }
  },
  created() {
    this.search()
  },
  methods: {
    select(selection) {
      this.selectionArr = selection
    },
    publishTopicOne(id) {
      this.$confirm(`确认发布所选话题吗 ?`, {
        type: 'warning'
      }).then(async () => {
        let paramsList = []
        paramsList.push(id)
        const { data: res } = await publishTopic(paramsList)
        if (res.resultCode === 200) {
          this.$message.success(`发布成功`)
          this.search()
        } else {
          this.$message.error('发布失败，请重试。')
        }
      })
        .catch(() => { })
    },

     publishTopicTwo(id) {
      this.$confirm(`确认取消发布吗 ?`, {
        type: 'warning'
      }).then(async () => {
        let paramsList = []
        paramsList.push(id)
        const { data: res } = await publishTopic(paramsList)
        if (res.resultCode === 200) {
          this.$message.success(`操作成功`)
          this.search()
        } else {
          this.$message.error('操作失败，请重试。')
        }
      })
        .catch(() => { })
    },
    async publishTopic(tip) {
      if (tip == 1) {
        this.$confirm(`确认批量发布所选话题吗 ?`, {
          type: 'warning'
        })
          .then(async () => {
            if (this.selectionArr.length == 0) {
              this.$message.error('请选择要发布的话题!')
              return false
            }
            let paramsList = []
            this.selectionArr.forEach(item => {
              paramsList.push(item.id)
            })
            // console.log(paramsList, 'paramsList');
            const { data: res } = await publishTopic(paramsList)
            if (res.resultCode === 200) {
              this.$message.success(`批量发布成功`)
              this.search()
            } else {
              this.$message.error('发布失败，请重试。')
            }
          })
          .catch(() => { })
      } else if (tip == 0) {
        this.$confirm(`确认批量删除所选话题吗 ?`, {
          type: 'warning'
        })
          .then(async () => {
            if (this.selectionArr.length == 0) {
              this.$message.error('请选择要删除的话题!')
              return false
            }
            let ids = []
            this.selectionArr.forEach(item => {
              ids.push(item.id)
            })
            const { data: res } = await deleteBusinessTopic(ids)
            if (res.resultCode === 200) {
              this.$message.success(`批量删除成功`)
              this.search()
            } else {
              this.$message.error('删除失败，请重试。')
            }
          })
          .catch(() => { })
      }
    },
    async deleteOne(id) {
      this.$confirm(`确认批量删除所选话题吗 ?`, {
        type: 'warning'
      }).then(async () => {
        let ids = []
        ids.push(id)
        const { data: res } = await deleteBusinessTopic(ids)
        if (res.resultCode === 200) {
          this.$message.success(`删除成功`)
          this.search()
        } else {
          this.$message.error('删除失败，请重试。')
        }
      })
        .catch(() => { })

    },
    //按钮选中css
    searchClick(index, status) {
      if (status == 1) {
        this.tabIndex1 = index
        this.queryInfo.topicType = this.tabIndex1
      }
      else {
        this.tabIndex2 = index
        this.queryInfo.isSued = this.tabIndex2
      }
      this.search()
    },
    async search() {
      this.loading = true
      const { data: res } = await getBusinessTopic(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        // console.log(this.dataUserList, 'this.dataUserList');
        this.total = res.data.total
        this.loading = false
      }
    },
    goAdd() {
      this.$router.push({
        path: `/businessTopics/addTopics`,
      })
    },
    goEdit(id) {
      this.$router.push({
        path: `/businessTopics/addTopics?id=` + id,
      })
    },
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
        topicType: '-1',
        isSued: '-1'
      }
      this.tabIndex1 = '-1',
        this.tabIndex2 = '-1',
        this.search()
    },
    goMessage(title, id) {
      this.$router.push({
        path: `/businessTopics/info?title=${title}&id=${id}`,
      })
    },
  },
}
</script> 

<style scoped lang="less">
.htmlTip {
  /deep/ p {
    width: 100%;
    color: #333333;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }

  /deep/ img {
    width: 100px;
    height: 100px;
  }

}

.el-button--primary {
  background: #7C71E1;
}

.el-button:hover,
.el-button:active {
  background: #7C71E1;
  color: #ffffff;
}

.active {
  background: #7C71E1;
  color: #ffffff;
  border: none;
}

.colorOne {
  color: #7C71E1;
  cursor: pointer;
}

.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;

  .line_one {
    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    padding: 20px 0px 0px 20px;
  }

  .line_two {
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;

    .buttonArea {
      display: flex;
      margin-top: 20px;

      div {
        width: 100px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }

      .add {
        background: #7C71E1;
        color: #ffffff;
      }

      .addSome {
        color: #7C71E1;
        border: 1px solid #7C71E1;

        .icon-jr-icon-meet {
          font-size: 18px;
        }
      }
    }

    .maker {
      display: flex;
      justify-content: center;

      div {
        font-size: 12px;
      }

      .put,
      .noPass {
        padding: 0px 10px;
        border-right: 1px solid #ccc;
        cursor: pointer;
      }

      .put {
        color: #7C71E1;
        cursor: pointer;
      }

      .edit,
      .delete {
        margin-left: 10px;
        color: #7C71E1;
        cursor: pointer;
      }
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }

  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}
</style>
